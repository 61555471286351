<template>
  <hero-navigation>
    <div class="projects-nav-elements">
      <template v-if="isManagerAndBigger">
        <router-link class="button-outline-blue" to="/accounting/dashboard">
          Accounting Dashboard
          <sup v-if="isAdmin" class="showStatus">M</sup>
        </router-link>
      </template>
      <template v-if="isManagerAndBigger">
        <router-link class="button-outline-blue" to="/accounting/invoices">
          Invoices
          <sup v-if="isAdmin" class="showStatus">M</sup>
        </router-link>
      </template>
      <template v-if="isManagerAndBigger">
        <router-link class="button-outline-blue" to="/accounting/am-commission">
          AM Commission
          <sup v-if="isAdmin" class="showStatus">A</sup>
        </router-link>
      </template>
      <template v-if="isManagerAndBigger">
        <router-link class="button-outline-blue" to="/accounting/zakhar-invoice-splitter">
          Zakhar invoice splitter
          <sup v-if="isAdmin" class="showStatus">A</sup>
        </router-link>
      </template>
    </div>
  </hero-navigation>
</template>
<script>
import HeroNavigation from '../components/HeroNavigation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'accounting-navigation',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['me', 'isAdmin', 'isManager', 'isManagerAndBigger'])
  },
  components: {
    HeroNavigation
  }
}
</script>
